import DeleteOutlineIcon from '@mui/icons-material/DeleteOutline';
import {
  Box,
  Button,
  Checkbox,
  FormControl,
  FormControlLabel,
  Grid,
  IconButton,
  InputLabel,
  MenuItem,
  Select,
  Typography,
} from '@mui/material';
import { DataGrid, GridColDef, GridRowId, GridRowProps } from '@mui/x-data-grid';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { CareTeamWrapper, WrappedCareTeam } from 'src/@nicheaim/fhir-base/wrappers/CareTeam';
import {
  OrganizationWrapper,
  WrappedOrganization,
} from 'src/@nicheaim/fhir-base/wrappers/Organization';
import { IdentifierCode, WrappedPatient } from 'src/@nicheaim/fhir-base/wrappers/Patient';
import {
  PractitionerWrapper,
  WrappedPractitioner,
} from 'src/@nicheaim/fhir-base/wrappers/Practitioner';
import {
  PractitionerRoleWrapper,
  WrappedPractitionerRole,
} from 'src/@nicheaim/fhir-base/wrappers/PractitionerRole';
import {
  RelatedPersonWrapper,
  WrappedRelatedPerson,
} from 'src/@nicheaim/fhir-base/wrappers/RelatedPerson';
import { ValueSetWrapper } from 'src/@nicheaim/fhir-base/wrappers/ValueSet';
import {
  useOrganizations,
  usePractitioners,
  useRelatedPersons,
  useValueSets,
} from 'src/@nicheaim/fhir-react';
import {
  CareTeamPermissions,
} from 'src/@types/crs/case';
import { fhirClient } from 'src/App';
import { GridItem, GridSection } from 'src/components/CustomModal';
import SearchTextField from 'src/components/SearchTextField';
import useObjectState from 'src/hooks/useObjectState';
import usePermissionsContext from 'src/hooks/usePermissionsContext';
import {
  Bundle,
  CareTeam,
  FhirResource,
  Organization,
  Practitioner,
  PractitionerRole,
} from 'src/nicheaim-infrastructure/application/adapters/out/repositories/fhir/resources';
import CellRow from 'src/sections/crs/common/CellRow';
import { NOTAPPLICABLE, isActiveCondition } from 'src/sections/crs/constants';
import { searchIfContainedInObj } from 'src/sections/crs/helpers/common';
import { ResourceWithIncludedResources } from 'src/sections/crs/types';
import { formatUSAddress } from 'src/utils/address';
import { getIncludedResources, getReferenceId, getReferenceType, getResourcesAndRelatedResources } from 'src/utils/fhir';
import { cleanSearchInput } from 'src/utils/string';
import { debounce } from 'src/utils/timers';
import useAuth from '../../../../../hooks/useAuth';
import { isArray, isString } from 'lodash';
import { getChildOrganizations } from '../SearchMember/api';
import { 
  getDefaultColumnWidth, 
  getMemberResourceTypes, 
  GridRow, 
  MemberResourceTypes, 
  MemberWrappedResourceTypes, 
  pagination, 
  SearchMemberProps
} from '../SearchMember/SearchMember';
import { CollapsibleRow } from '../CollapsibleDataGrid';
import { ExpandLess as ExpandLessIcon, ExpandMore as ExpandMoreIcon, Mail, Phone } from '@mui/icons-material';
import { wrapResource } from 'src/utils/fhirSystem';
import uuidv4 from 'src/utils/uuidv4';
import useTranslations from 'src/hooks/useTranslations';
import useTenantConfigData from 'src/hooks/useTenantConfigData';

export interface SearchMemberState {
  searchTextField: string;
  filter: string | null;
  resourceType: string | null;
}

const CareTeamSearchMember = ({
  externalResourceType,
  onSelectResource,
  onClear,
  patient,
  showMemberTypeField = false,
  isLoading,
  headerComponents,
}: SearchMemberProps) => {
  const user = useAuth();

  const { componentsData } = useTenantConfigData();
  const { careTeamSearchMember } = componentsData?.careTeam ?? {};

  const translate = useTranslations('practitioner');

  const [allowedOrganizations, setAllowedOrganizations] = useState<any[]>([]);

  const userOrgFhirId = useMemo(() => {
    if (user) {
      const selectedOrg = user.getSelectedOrg();
      if (
        selectedOrg &&
        selectedOrg?.fhir_uri &&
        isString(selectedOrg?.fhir_uri) &&
        selectedOrg?.fhir_uri.includes('Organization/')
      )
        try {
          return selectedOrg?.fhir_uri.split('Organization/')?.[1];
        } catch (error) {
          return null;
        }
    }
    return null;
  }, [user]);

  const getAllowedOrganizations = async (fhirId: string) => {
    if (fhirId) {
      try {
        const result = await getChildOrganizations([fhirId]);
        if (result && isArray(result)) {
          setAllowedOrganizations(result);
        }
      } catch (error) {
        console.log(error);
      }
    }
  };

  const [includeMembersInactive, setIncludeMembersInactive] = useState<any>([]);

  useEffect(() => {
    if (userOrgFhirId) {
      getAllowedOrganizations(userOrgFhirId);
    }
  }, [userOrgFhirId]);

  const [{ filter, searchTextField, resourceType }, updateState] =
    useObjectState<SearchMemberState>({
      searchTextField: '',
      filter: null,
      resourceType: null
    });

  const [{ practitionerRoles, isPractitionerRolesLoading }, updatePractitionerRoleState] =
    useObjectState<{
      isPractitionerRolesLoading: boolean;
      practitionerRoles: ResourceWithIncludedResources[];
    }>({
      practitionerRoles: [],
      isPractitionerRolesLoading: false,
    });

  const [{ careTeams, isCareTeamsLoading }, updateCareTeamState] =
    useObjectState<{
      careTeams: {
        resource: FhirResource,
        includedResources: (FhirResource | ResourceWithIncludedResources<FhirResource>)[]
      }[];
      isCareTeamsLoading: boolean;
    }>({
      careTeams: [],
      isCareTeamsLoading: false,
    });

  const practitionerFilter = useMemo(() => {
    const cleansedSearch = filter ? cleanSearchInput(filter) : '';
    if (!cleansedSearch.length) return isActiveCondition;
    const splittedSearch = cleansedSearch ? cleansedSearch.split(' ') : [];
    const givenName = splittedSearch?.[0] ?? '';
    const familyName = splittedSearch.slice(1).join(' ');

    return splittedSearch.length === 1
      ? {
          name: givenName,
          ...isActiveCondition,
        }
      : {
          given: givenName,
          ...(familyName ? { family: familyName } : {}),
          ...isActiveCondition,
        };
  }, [filter]);

  const patientGeneralPracPractitionerRoles: string[] = useMemo(() => {
    if (!patient?.generalPractitioner) {
      return [];
    }

    let resoucesIds: string[] = [];

    patient.generalPractitioner.forEach((item) => {
      if (item.reference) {
        const splitRef = item.reference.split('/');
        const id = splitRef[splitRef.length - 1];
        resoucesIds.push(id);
      }
    });

    return resoucesIds;
  }, [patient]);

  const getPractitionerRoles = useCallback(async () => {
    if (resourceType !== 'PractitionerRole' && resourceType !== 'Me') return;
    try {
      updatePractitionerRoleState({ isPractitionerRolesLoading: true });
      const cleansedSearch = filter ? cleanSearchInput(filter) : '';
      const splittedSearch = cleansedSearch ? cleansedSearch.split(' ') : [];
      const givenName = splittedSearch?.[0] ?? '';
      const familyName = splittedSearch.slice(1).join(' ');

      const userPractitionerId = getReferenceId(user?.getCurrentUser()?.user_fhir_uri);

      const includeQuery = `_include=PractitionerRole:practitioner&_include=PractitionerRole:organization`;

      const query =
        resourceType === 'Me'
          ? `${includeQuery}&practitioner=${userPractitionerId}&organization=${userOrgFhirId}`
          : includeQuery;

      const filterPRByPractitioners = {
        ...(givenName && familyName
          ? {
              'practitioner.given': givenName,
              ...(familyName
                ? {
                    'practitioner.family': familyName,
                  }
                : {}),
            }
          : {
              'practitioner.name': cleansedSearch,
            }),
        ...isActiveCondition,
      };
      const filterPRByOrganizations = {
        ...(cleansedSearch && { 'organization.name': cleansedSearch }),
        ...isActiveCondition,
      };
      const filterPR = {
        ...(cleansedSearch ? { 'role:text': cleansedSearch } : {}),
        ...isActiveCondition,
      };

      const practitionerRolesResourcesUrl = `PractitionerRole?${new URLSearchParams(
        filterPR
      ).toString()}&${new URLSearchParams(filterPRByOrganizations).toString()}&${query}`;
      
      const practitionerRolesResources = await fhirClient.get<Bundle>(
        practitionerRolesResourcesUrl
      );

      const pRolesByPractitioner = cleansedSearch
        ? await fhirClient.get<Bundle>(
            `PractitionerRole?${new URLSearchParams(
              filterPRByPractitioners
            ).toString()}&${query}`
          )
        : null;

        const pRolesByOrganization = await fhirClient.get<Bundle>(
          `PractitionerRole?${new URLSearchParams(
            filterPRByOrganizations
          ).toString()}&${query}`
        );

      const patientPractitionerRolesResources =
        patientGeneralPracPractitionerRoles &&
        patientGeneralPracPractitionerRoles.length > 0 &&
        cleansedSearch === '' && resourceType !== 'Me'
          ? await fhirClient.get<Bundle>(
              `PractitionerRole?_id=${patientGeneralPracPractitionerRoles.join(',')}`
            )
          : null;

      const [practitionerRoles, relatedResources] = getResourcesAndRelatedResources(
        [
          ...(patientPractitionerRolesResources?.entry ?? []),
          ...(practitionerRolesResources?.entry ?? []),
          ...(pRolesByPractitioner?.entry ?? []),
          ...(pRolesByOrganization?.entry ?? []),
        ].reduce<FhirResource[]>((resources, { resource }) => {
          if (resource) return [...resources, resource];
          return resources;
        }, []),
        'PractitionerRole'
      );
      updatePractitionerRoleState({
        practitionerRoles: (practitionerRoles as PractitionerRole[]).map((practitionerRole) => {
          const organization = relatedResources.find(
            ({ id }) => id === practitionerRole?.organization?.reference?.split?.('/')?.[1]
          );
          const practitioner = relatedResources.find(
            ({ id }) => id === practitionerRole?.practitioner?.reference?.split?.('/')?.[1]
          );
          return {
            resource: PractitionerRoleWrapper(practitionerRole),
            includedResources: [
              ...(organization ? [OrganizationWrapper(organization as Organization)] : []),
              ...(practitioner ? [PractitionerWrapper(practitioner as Practitioner)] : []),
            ],
          };
        }),
      });
    } catch (error) {}

    updatePractitionerRoleState({ isPractitionerRolesLoading: false });
  }, [filter, resourceType, allowedOrganizations]);

  useEffect(() => {
    if (allowedOrganizations && allowedOrganizations.length > 0) {
      getPractitionerRoles();
    }
  }, [getPractitionerRoles, allowedOrganizations]);

  const { isAllowedToAdd } =
    usePermissionsContext<CareTeamPermissions['members']>() ?? {};

  useEffect(() => {
    if (!externalResourceType) return;
    updateState({ resourceType: externalResourceType });
  }, [externalResourceType]);

  const [rolesRecords] = useValueSets({
    filter: {
      identifier: 'ph-roles-codes',
    },
    map: ValueSetWrapper,
  });

  const roles = useMemo(() => rolesRecords?.[0]?.asListAll() ?? null, [rolesRecords]);

  const getCareTeamWithMembers = useCallback(async () => {
    if (resourceType !== 'CareTeam') return [];
    try {
      updateCareTeamState({ isCareTeamsLoading: true });
      const url =  `CareTeam?_include=CareTeam:participant`;
      const response = await fhirClient.get<Bundle>(url);
      if (!response?.entry?.length) return [];
      let entries = response.entry.reduce<FhirResource[]>((resources, { resource }) => {
        if (!resource) return resources;
        return [...resources, resource];
      }, []);
      const careTeams = entries.filter(
        ({ resourceType }) => resourceType === 'CareTeam'
      ) as CareTeam[];

      const otherCareTeams = careTeams?.filter(
        (careTeam) =>
          !careTeam.subject &&
          careTeam.managingOrganization?.some(
            ({ reference }) => getReferenceId(reference) === userOrgFhirId
          )
      );

      const patientCareTeams = careTeams?.filter(
        (careTeam) =>
          getReferenceId(careTeam.subject?.reference) === patient?.id
      );

      const uniqueCareTeams = [
        ...new Map(
          [...otherCareTeams, ...patientCareTeams].map((careTeam) => [careTeam.id, careTeam])
        ).values(),
      ];

      const practitionerRoleRelatedResources = await getPractitionerRoleRelatedResources(uniqueCareTeams);

      entries = [...entries, ...practitionerRoleRelatedResources];

      const mappedCareTeams = uniqueCareTeams
      .reduce<{
        resource: FhirResource,
        includedResources: (FhirResource | ResourceWithIncludedResources<FhirResource>)[]
      }[]>(
        (resources, careTeamUnwrapped) => {
          if (!careTeamUnwrapped?.id) return resources;
          const careTeam = CareTeamWrapper(careTeamUnwrapped as CareTeam);

          const participant =
            careTeam?.participant?.reduce?.<FhirResource[]>((resources, { member }) => {
              const resource = entries.find?.(({ id }) => id === getReferenceId(member?.reference));
              if (!resource) return resources;
              return [...resources, resource];
            }, []) ?? [];

          const prRelatedResources = participant.reduce<ResourceWithIncludedResources[]>((resources, resource) => {
            if (resource?.resourceType !== 'PractitionerRole') return resources;
            const practitionerRole = resource as PractitionerRole;

            const practitioner = entries.find(
              ({ id }) => id === getReferenceId(practitionerRole?.practitioner?.reference)
            );
            const practitionerRoleWithPractitioner = { 
              resource: PractitionerRoleWrapper(practitionerRole), 
              includedResources: practitioner && practitioner.resourceType === 'Practitioner' ? [PractitionerWrapper(practitioner)] : [] 
            };
            
            return [
              ...resources,
              practitionerRoleWithPractitioner,
            ];
          }, []);

          const careTeamsFromCareTeam = participant?.filter((e) => e?.resourceType === 'CareTeam') ?? [];

          return [
            ...resources,
            {
              resource: careTeam,
              includedResources: [
                ...careTeamsFromCareTeam,
                ...prRelatedResources,
              ],
            },
          ];
        },
        []
      );
      updateCareTeamState({ careTeams: mappedCareTeams });
    } catch (error) { return []} 
    finally { updateCareTeamState({ isCareTeamsLoading: false }); }
  }, [resourceType, patient, userOrgFhirId]);

  useEffect(() => {
    getCareTeamWithMembers();
  }, [getCareTeamWithMembers]);

  const [practitioners, { isFetching: isPractitionerFetching }] = usePractitioners({
    filter: practitionerFilter,
    map: PractitionerWrapper,
    autofetch: resourceType === 'Practitioner',
    ...pagination,
  });

  const [organizations, { isFetching: isOrganizationsFetching }] = useOrganizations({
    filter: {
      _content: filter || undefined,
    },
    map: OrganizationWrapper,
    autofetch: resourceType === 'Organization',
    ...pagination,
  });

  const [relatedPersons, { isFetching: isRelatedPerson }] = useRelatedPersons({
    filter: {
      name: filter || undefined,
      patient: patient?.id ?? undefined,
    },
    map: RelatedPersonWrapper,
    autofetch: resourceType === 'RelatedPerson',
    ...pagination,
  });

  const careTeamsFilter = useMemo(() => {
    if (resourceType !== 'CareTeam') return [];
    if (!filter) return careTeams || [];
    const searchByString = filter?.toLowerCase().trim() ?? '';
    let filteredCareTeams: any = [];
    if (searchByString.length >= 3) {
      filteredCareTeams = careTeams?.filter(
        (careTeam) =>
          searchIfContainedInObj(careTeam?.resource, ['name'], searchByString)
      );
    }
    return filteredCareTeams;
  }, [resourceType, careTeams, filter]);

  const memoizedFilteredCareTeams = useMemo(() => {
    return careTeamsFilter.map((careTeam) => ({
      ...careTeam,
      includedResources: getIncludedResources(careTeam, includeMembersInactive),
    }));
  }, [careTeamsFilter, includeMembersInactive, getIncludedResources]);

  const isFetching =
    isCareTeamsLoading ||
    isPractitionerFetching ||
    isPractitionerRolesLoading ||
    isOrganizationsFetching ||
    isRelatedPerson;

  const getResourceByResourceType = useCallback(
    (resourceType: string) => {
      switch (resourceType) {
        case 'CareTeam':
          return memoizedFilteredCareTeams;
        case 'Practitioner':
          return practitioners;
        case 'PractitionerRole':
          return practitionerRoles;
        case 'Organization':
          return organizations;
        case 'RelatedPerson':
          return relatedPersons;
        case 'Patient':
          return [patient];
        case 'Me': 
          return practitionerRoles;
      }
    },
    [
      patient, 
      memoizedFilteredCareTeams, 
      practitioners, 
      practitionerRoles, 
      organizations, 
      relatedPersons
    ]
  );

  const handleFilterChange = useCallback(
    debounce((value) => {
      updateState({ filter: value });
    }, 600),
    []
  );

  const showMemberTypeSelectionField = showMemberTypeField;
  const memberTypes = getMemberResourceTypes(!!patient)

  const [openRows, setOpenRows] = useState<GridRowId[]>([]);
  
  const toggleRow = (id: string) => {
    setOpenRows((openRows) => {
      if (openRows.includes(id)) {
        return openRows.filter((openRow) => openRow !== id);
      }
      return [...openRows, id];
    });
  };

  const defaultColumns: Record<string, GridColDef> = useMemo(
    () => ({
      expand: {
        field: 'expand',
        headerName: '',
        sortable,
        ...getDefaultColumnWidth(resourceType),
        renderCell: (params) => {
          return (
            <IconButton
              sx={{ padding: 0 }}
              onClick={() => {
                toggleRow(params?.row?.id);
              }}
            >
              {openRows.includes(params?.row?.id) ? <ExpandLessIcon /> : <ExpandMoreIcon />}
            </IconButton>
          )
        }
      },
      name: {
        field: 'name',
        headerName: 'Name',
        sortable,
        ...getDefaultColumnWidth(resourceType)
      },
      memberType: {
        field: 'memberType',
        headerName: 'Member Type',
        sortable,
        ...getDefaultColumnWidth(resourceType),
      },
      identifier: {
        field: 'identifier',
        headerName: 'Identifier',
        sortable,
        ...getDefaultColumnWidth(resourceType),
      },
      associatedOrg: {
        field: 'associatedOrg',
        headerName: 'Managing Organization(s)',
        sortable,
        ...getDefaultColumnWidth(resourceType),
      },
      role: {
        field: 'role',
        headerName: 'Role',
        sortable,
        ...getDefaultColumnWidth(resourceType),
      },
      address: {
        field: 'address',
        headerName: 'Address',
        sortable,
        ...getDefaultColumnWidth(resourceType),
        renderCell: (params) => {
          const row = params.row as GridRow;
          const organization = row?.resource as WrappedOrganization;

          return (
            <CellRow
              shouldTruncateText={false}
              title={formatUSAddress(organization?.getPrimaryAddress()) ?? ''}
            />
          );
        },
      },
    }),
    [resourceType, roles]
  );

  const columnsMapping: ResourceMapping<GridColDef[]> = useMemo(
    () => ({
      Practitioner: [
        {
          ...defaultColumns.name,
          flex: 1,
        },
        {
          ...defaultColumns.identifier,
          flex: 1,
        },
      ],
      Patient: [
        {
          ...defaultColumns.name,
          flex: 1,
        },
        {
          ...defaultColumns.identifier,
          flex: 1,
        },
      ],
      CareTeam: [
        {
          ...defaultColumns.expand,
          flex: 0.2,
        },
        {
          ...defaultColumns.name,
          flex: 1,
        },
        {
          ...defaultColumns.associatedOrg,
          flex: 1,
        },
      ],
      PractitionerRole: [
        {
          ...defaultColumns.name,
          flex: 1,
        },
        {
          ...defaultColumns.role,
          flex: 1,
        },
        {
          ...defaultColumns.associatedOrg,
          flex: 1,
        },
      ],
      Organization: [
        {
          ...defaultColumns.name,
          flex: 1,
        },
        {
          ...defaultColumns.identifier,
          flex: 1,
        },
        {
          ...defaultColumns.address,
          flex: 2,
        },
      ],
      RelatedPerson: [
        {
          ...defaultColumns.name,
          flex: 1,
        },
        {
          ...defaultColumns.identifier,
          flex: 1,
        },
      ],
    }),
    [defaultColumns]
  );

  const actionButtonColumn: GridColDef = useMemo(
    () => ({
      field: 'edit',
      headerName: '',
      align: 'center',
      sortable: false,
      renderCell: (params) => {
        const row = params.row as any;

        return (
          <Button
            disabled={!!isLoading || !isAllowedToAdd}
            color={'primary'}
            sx={{
              height: 36,
              width: 63,
            }}
            onClick={() => {
              onSelectResource?.(
                row?.resource || row as MemberWrappedResourceTypes,
                row?.includedResources ?? []
              );
            }}
            variant="contained"
          >
            {'Select'}
          </Button>
        );
      },
    }),
    [onSelectResource]
  );

  const getRows = useCallback(() => {
    const resources = getResourceByResourceType(resourceType as string);
    
    return resources?.map?.((resource) =>
      getRowsMapping?.[resourceType === 'Me' ? 'PractitionerRole' : resourceType as MemberResourceTypes]?.(
        resource as MemberWrappedResourceTypes
      )
    );
  }, [resourceType, getResourceByResourceType, filter]);

  const getColumns = useCallback(() => {
    const columnsByResourceType = (columnsMapping?.[resourceType === 'Me' ? 
        'PractitionerRole' : resourceType as MemberResourceTypes] ??
      []) as GridColDef[];

    if (resourceType)
      return [...columnsByResourceType, 
        ...(resourceType === 'CareTeam' && 
          careTeamSearchMember?.selectAllowed?.selectCareTeam
            ? [actionButtonColumn] : resourceType !== 'CareTeam'
            ? [actionButtonColumn]: []
        )
      ];

    return [
      ...Object.values(defaultColumns),
      ...(resourceType === 'CareTeam' && 
        careTeamSearchMember?.selectAllowed?.selectCareTeam
          ? [actionButtonColumn] : resourceType !== 'CareTeam'
          ? [actionButtonColumn]: []
      )
    ];
  }, [
    defaultColumns,
    actionButtonColumn,
    resourceType,
    columnsMapping
  ]);

  const rows = useMemo(() => getRows(), [getRows]);

  const columns = useMemo(() => getColumns(), [getColumns]);

  function handleChange(id: string): void {
    const updatedState = includeMembersInactive.includes(id)
      ? includeMembersInactive.filter((memberId) => memberId !== id)
      : [...includeMembersInactive, id];
  
    setIncludeMembersInactive(updatedState);
  };

  return (
    <>
      <GridSection mt={0}>
        {showMemberTypeSelectionField && (
          <GridItem xs={3}>
            <FormControl fullWidth>
              <InputLabel> {'Owner Type'}</InputLabel>
              <Select
                disabled={!!isLoading}
                value={resourceType}
                label={'Owner Type'}
                onChange={(event) => {
                  updateState({ resourceType: event.target.value });
                }}
              >
                {memberTypes.map(({ label, value }) => (
                  <MenuItem key={value} value={value}>
                    {label}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </GridItem>
        )}
        <GridItem
          xs={!showMemberTypeSelectionField ? 12 : 8.7}
          display={'flex'}
          justifyContent={'row'}
        >
          <SearchTextField
            disabled={!!isLoading}
            value={searchTextField}
            onChange={(event) => {
              const { value } = event.target;
              updateState({
                searchTextField: value,
              });
              handleFilterChange(value);
            }}
            placeholder={`Search ${
              titleMapping?.[resourceType as MemberResourceTypes]
                ? `by ${titleMapping[resourceType as MemberResourceTypes]}`
                : ''
            }...`}
            fullWidth
          />
          <Button
            disabled={!!isLoading}
            sx={{
              ml: 2,
              paddingY: 2,
              paddingX: 3,
              alignSelf: 'center',
              color: '#ff5630',
              width: 78,
              height: 26,
              border: 0,
              '&:hover': { border: 0, backgroundColor: 'rgba(255, 86, 48, 0.16)' },
            }}
            onClick={() => {
              updateState({
                searchTextField: '',
                resourceType: null,
                filter: null,
              });
              onClear?.();
            }}
            variant="outlined"
            color="inherit"
          >
            <DeleteOutlineIcon htmlColor={'#ff5630'} sx={{ mr: '1px' }} />
            <Typography
              color={'#ff5630'}
              fontSize={'0.9rem'}
              fontWeight={'bold'}
              sx={{ mr: '2px' }}
            >
              Clear
            </Typography>
          </Button>
        </GridItem>
      </GridSection>

      {headerComponents ? (
        <Grid container xs={12} mt={1} mb={1}>
          <Grid item xs={12} sx={{ display: 'flex' }}>
            {headerComponents}
          </Grid>
        </Grid>
      ) : null}

      <GridSection mt={headerComponents ? 0 : 3}>
        <GridItem xs={12}>
          <Box sx={{ width: '100%', height: 450 }}>
            {(!!resourceType) && (
              <DataGrid
                rows={rows ?? []}
                sx={{
                  width: '100% !important',
                  '& .MuiDataGrid-columnHeader': { backgroundColor: '#f4f6f8' },
                  '& .MuiDataGrid-cell': {
                    border: 0,
                  },
                  '& .MuiDataGrid-row': {
                    '&:hover': {
                      backgroundColor: 'transparent',
                    },
                  },
                }}
                getRowId={() => uuidv4()}
                loading={isFetching}
                getRowSpacing={() => ({ bottom: 5 })}
                getRowHeight={() => 'auto'}
                getEstimatedRowHeight={() => 90}
                columns={columns}
                components={{
                  Row: (rowProps: GridRowProps) => (
                    <CollapsibleRow
                      rowProps={rowProps}
                      openRows={openRows}
                      renderCollapsibleContent={(careTeam: any) => {
                        const countMember = careTeam?.includedResources?.length;
                        return  (
                          <Box 
                            sx={{ my: 2}}
                          >
                            {countMember ? (
                              <Box display="flex" flexDirection="row" alignItems="center">
                                <Typography variant="body2">{`${countMember} Members`}</Typography>
                                <FormControlLabel
                                  control={
                                    <Checkbox
                                      checked={includeMembersInactive?.includes(careTeam?.resource?.id || "")}
                                      name="includeInactive"
                                      onChange={() => handleChange(careTeam?.resource?.id || "")}
                                    />
                                  }
                                  label="Include Inactive"
                                  style={{ marginLeft: 'auto' }}
                                />
                              </Box>
                            ): null}

                            <DataGrid
                              columns={[
                                {
                                  field: 'display',
                                  headerName: 'Name',
                                  flex: 1,
                                  sortable: false,
                                  renderCell: (params) => {
                                    const rowResource = params.row;
                                    const { name = null, includedResources = null } = rowResource;
                                    const wrappedIncludedR = includedResources?.[0] ? wrapResource(includedResources[0]) : null;
                                    const getFullName = wrappedIncludedR?.getFullName ?? null;
                                    return name ?? (getFullName ? getFullName() : "");
                                  }
                                },
                                {
                                  field: 'contact',
                                  headerName: 'Contact Details',
                                  flex: 1,
                                  sortable: false,
                                  renderCell: (params) => {
                                    const rowResource = params.row;
                                    const { includedResources = null } = rowResource;
                                    const wrappedIncludedR = includedResources?.[0] ? wrapResource(includedResources[0]) : null;
                                    const getPhone = wrappedIncludedR?.getPrimaryPhone ? wrappedIncludedR?.getPrimaryPhone()?.value : null;
                                    const getEmail = wrappedIncludedR?.getPrimaryEmail ? wrappedIncludedR?.getPrimaryEmail()?.value : null;

                                    return (
                                      <Box>
                                        <CellRow
                                          tooltipTitle={translate('phone')}
                                          shouldTruncateText={false}
                                          title={getPhone}
                                          Icon={<Phone sx={{ height: 15, width: 15 }} htmlColor={"#919eab"} />}
                                        />
                                        <CellRow
                                          tooltipTitle={translate('email')}
                                          shouldTruncateText={false}
                                          title={getEmail}
                                          Icon={<Mail sx={{ height: 15, width: 15 }} htmlColor={"#919eab"} />}
                                        />
                                      </Box>
                                    )
                                  }
                                },
                                {
                                  field: 'role',
                                  headerName: 'Role',
                                  flex: 1,
                                  sortable: false,
                                  renderCell: (params) => {
                                    const rowResource = params.row;
                                    const { resource = null } = rowResource;
                                    const roleFromCareTeam = careTeam?.resource?.participant?.find((e) => 
                                      getReferenceId(e?.member?.reference) === resource?.id)?.role?.[0];
                                      
                                    return roleFromCareTeam?.text ?? roleFromCareTeam?.coding?.[0]?.display ?? "";
                                  }
                                },
                                ...careTeamSearchMember?.selectAllowed?.selectMember ? 
                                  [actionButtonColumn] : [],
                              ]}
                              getRowId={() => uuidv4()}
                              autoHeight={true}
                              getRowSpacing={() => ({ bottom: 5 })}
                              getRowHeight={() => 'auto'}
                              getEstimatedRowHeight={() => 90}
                              rows={careTeam?.includedResources ?? []}
                              components={{ Footer: () => null}}
                              isRowSelectable={() => false}
                              disableColumnMenu
                            />
                          </Box>
                        )
                      }}
                    />
                  ),
                  Footer: () => null
                }}
                isRowSelectable={() => false}
                disableColumnMenu
              />
            )}
          </Box>
        </GridItem>
      </GridSection>
    </>
  );
};

type ResourceMapping<T> = {
  [k in MemberResourceTypes]?: T;
};

const titleMapping: ResourceMapping<string> = {
  PractitionerRole: 'Practitioner Role',
};

const sortable = false;

const getRowsMapping: ResourceMapping<(resource: MemberWrappedResourceTypes) => GridRow> = {
  Practitioner: (resource) => {
    const { getFullName, id, getIdentifier, resourceType } = resource as WrappedPractitioner;
    const { identifier, type: identifierType } = getIdentifier();
    return {
      id: id ?? '',
      identifier: identifier ?? '',
      identifierType,
      name: getFullName() ?? '',
      associatedOrg: NOTAPPLICABLE,
      memberType: resourceType,
      resource,
    };
  },
  Patient: (resource) => {
    const { getFullName, id, getIdentifier, resourceType } = resource as WrappedPatient;
    return {
      id: id ?? '',
      identifier: getIdentifier(IdentifierCode.MEDICAL_RECORD_NUMBER)?.value ?? '',
      name: getFullName() ?? '',
      memberType: resourceType,
      resource,
    };
  },
  CareTeam: (resource) => {
    const { resource: careTeam, includedResources } = (resource ??
      {}) as unknown as ResourceWithIncludedResources;

    const { name, id, resourceType, managingOrganizationId, managingOrganizationName } = 
      (careTeam ?? {}) as WrappedCareTeam;

    return {
      id: id ?? '',
      name,
      memberType: resourceType,
      associatedOrg: managingOrganizationName ?? managingOrganizationId,
      associatedOrgId: managingOrganizationId,
      associatedOrgName: managingOrganizationName,
      resource: careTeam as WrappedCareTeam,
      includedResources
    };
  },
  PractitionerRole: (resource) => {
    const { resource: practitionerRole, includedResources } = (resource ??
      {}) as unknown as ResourceWithIncludedResources;

    const {
      getIdentifier,
      getPractitionerName,
      getRoleName,
      getRoleId,
      resourceType,
      getOrganizationId,
      getOrganizationName,
    } = (practitionerRole ?? {}) as WrappedPractitionerRole;

    const { identifier, type: identifierType } = getIdentifier();
    return {
      id: practitionerRole?.id ?? '',
      identifier: identifier ?? '',
      identifierType,
      name: getPractitionerName((includedResources ?? []) as WrappedPractitioner[]),
      memberType: resourceType,
      role: getRoleName() ?? getRoleId(),
      roleId: getRoleId(),
      roleName: getRoleName(),
      associatedOrg: getOrganizationName() ?? getOrganizationId(),
      associatedOrgId: getOrganizationId(),
      associatedOrgName: getOrganizationName((includedResources ?? []) as WrappedOrganization[]),
      resource: practitionerRole as WrappedPractitionerRole,
      includedResources,
    };
  },
  Organization: (resource) => {
    const { id, resourceType, name } = resource as WrappedOrganization;
    return {
      id: id ?? '',
      identifier: id ?? '',
      name: name ?? '',
      memberType: resourceType,
      resource,
    };
  },
  RelatedPerson: (resource) => {
    const { id, resourceType, getFullName } = (resource ?? {}) as WrappedRelatedPerson;
    return {
      id: id ?? '',
      identifier: id ?? '',
      name: getFullName?.() ?? '',
      memberType: resourceType,
      resource,
    };
  },
};

const getPractitionerRoleRelatedResources = async (careTeams: CareTeam[]) => {
  try {
    const practitionerRoleIds = careTeams.reduce<string[]>((resourceIds, careTeam) => {
      const ids = careTeam?.participant
        ?.filter?.(
          ({ member }) =>
            getReferenceType(member?.reference) === 'PractitionerRole' && !!getReferenceId(member?.reference)
        )
        ?.map?.(({ member }) => getReferenceId(member?.reference)) as string[];
      if (!ids?.length) return resourceIds;
      return [...resourceIds, ...ids];
    }, []);
    if (!practitionerRoleIds.length) return [];

    const response = await fhirClient.get<Bundle>(
      `PractitionerRole?_id=${[
        ...new Set(practitionerRoleIds),
      ].join()}&_include=PractitionerRole:practitioner`
    );

    return (
      response?.entry?.reduce?.<FhirResource[]>((resources, { resource }) => {
        if (!resource) return resources;
        return [...resources, resource];
      }, []) ?? []
    );
  } catch (error) {
    return [];
  }
};

export default CareTeamSearchMember;