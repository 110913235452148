import React, { useEffect, useMemo } from 'react';
import { TextField, Grid, Autocomplete } from '@mui/material';
import { WrappedPatient } from 'src/@nicheaim/fhir-base/wrappers/Patient';
import CustomModal, {
  CustomModalBasicProps,
  GridItem,
  GridSection,
  gridItemColumnSize,
} from 'src/components/CustomModal';
import { WrappedPlanDefinition } from 'src/@nicheaim/fhir-base/wrappers/PlanDefinition';
import { onSuccess } from 'src/@types/crs/case';
import useObjectState from 'src/hooks/useObjectState';
import DatePickerMoment from 'src/components/DatePickerMoment';
import moment, { DurationInputArg2 } from 'moment';
import {
  Coding,
  Condition,
  Observation,
  ValueSetComposeIncludeConcept,
} from '../../../../../nicheaim-infrastructure/application/adapters/out/repositories/fhir/resources/resources';
import { WrappedCarePlan } from 'src/@nicheaim/fhir-base/wrappers/CarePlan';
import * as goalApiHelpers from 'src/services/api/case';
import { useSnackbar } from 'notistack';
import { WrappedGoal } from 'src/@nicheaim/fhir-base/wrappers/Goal';
import SearchMember, {
  SearchMemberProps,
  getMemberResourceTypes,
} from '../SearchMember/SearchMember';
import {
  TaskSelectedMemberOption,
  TaskSelectedMemberState,
  TaskSelectedMemberValue,
  getOwnerDisplayLabel,
} from '../TasksGrid/TaskModal';
import useValueSetsByIdentifiers from 'src/hooks/useValueSetsByIdentifier';
import {
  getConditionsByGoal,
  getObservationsByGoal,
  getValueSetConceptValue,
} from 'src/sections/crs/helpers/common';
import { WrappedCondition } from '../../../../../@nicheaim/fhir-base/wrappers/Condition';
import useLocales from 'src/hooks/useLocales';
import useGoalContext from 'src/hooks/useGoalContext';
import GoalInfoRibbon from './GoalInfoRibbon';
import { WrappedObservation } from 'src/@nicheaim/fhir-base/wrappers/Observation';

export interface ActionData {
  payload: any;
  successfulMessage: string;
  actionCall: Function;
}
export interface GoalModalProps extends CustomModalBasicProps {
  pathways: ValueSetComposeIncludeConcept[];
  onSuccessfulCreation: onSuccess;
  onSuccessfulEdit: onSuccess;
  patient: WrappedPatient | null;
  goalToEdit: WrappedGoal | null;
  planDefinitions: WrappedPlanDefinition[];
  conditions: WrappedCondition[];
  observations: WrappedObservation[];
  carePlan?: WrappedCarePlan | null;
  defaultPathway?: ValueSetComposeIncludeConcept;
  defaultAddresses?: (WrappedCondition | WrappedObservation)[];
  defaultPlanDefinition?: WrappedPlanDefinition;
}

export interface GoalModalState {
  selectedPlanDefinition: WrappedPlanDefinition | null;
  selectedConditions: WrappedCondition[];
  selectedObservations: WrappedObservation[];
  selectedAddresses: (WrappedCondition | WrappedObservation)[];
  selectedLifeCycleStatus: GoalLifeCycleStatusOption | null;
  selectedAchievementStatus: GoalAchievementStatusOption | null;
  selectedPathway: ValueSetComposeIncludeConcept | null;
  description: string;
  comments: string;
  startDate: moment.Moment | null;
  endDate: moment.Moment | null;
  isLoading: boolean;
  taskOwner: TaskSelectedMemberValue | null;
}
export interface ErrorFieldState {
  owner?: string | null;
  lifecycleStatus?: string | null;
  category?: string | null;
  startDate?: string | null;
  description?: string | null;
  pathway?: string | null;
  endDate?: string | null;
}

export interface GoalDataFromPlanDefinition {
  lifecycleStatus: ValueSetComposeIncludeConcept | null;
  achievementStatus: Coding | undefined;
  goalStartDate: moment.Moment | null;
  goalEndDate: moment.Moment | null;
  description: string;
}

const getInitialState = ({
  defaultPathway,
  goalToEdit,
  pathways,
  goalStatuses,
  conditions,
  observations,
  defaultAddresses,
  defaultPlanDefinition,
}: {
  defaultPathway: ValueSetComposeIncludeConcept | null | undefined;
  goalToEdit: WrappedGoal | null | undefined;
  pathways: ValueSetComposeIncludeConcept[];
  conditions: WrappedCondition[];
  observations: WrappedObservation[];
  goalStatuses: GoalLifeCycleStatusOption[];
  defaultAddresses?: (WrappedCondition | WrappedObservation)[];
  defaultPlanDefinition?: WrappedPlanDefinition;
}): GoalModalState => {
  const lifecycleStatus = getValueSetConceptValue(goalStatuses ?? [], goalToEdit?.lifecycleStatus);
  const achievementStatus = goalToEdit?.getAchievementStatus?.();

  let startDate: moment.Moment | null = moment();
  let endDate: moment.Moment | null = null;

  if (goalToEdit) {
    startDate = moment(goalToEdit?.startDate ?? null).isValid()
      ? moment(goalToEdit?.startDate)
      : null;
    endDate = moment(goalToEdit?.getEndDate?.() ?? null).isValid()
      ? moment(goalToEdit?.getEndDate?.())
      : null;
  }
  const pathway = goalToEdit?.getPathway()
    ? pathways.find(({ code }) => code === goalToEdit?.getPathway())
    : null;

  let initialConditions: WrappedCondition[] = [];

  const existingConditionRefs = goalToEdit?.addresses?.filter(
    (x) => x?.reference?.startsWith('Condition') ?? []
  );

  if (goalToEdit && existingConditionRefs && existingConditionRefs.length > 0) {
    initialConditions = getConditionsByGoal(goalToEdit, conditions);
  }

  let initialObservations: WrappedObservation[] = [];

  const existingObservationRefs = goalToEdit?.addresses?.filter(
    (x) => x?.reference?.startsWith('Observation') ?? []
  );

  if (goalToEdit && existingObservationRefs && existingObservationRefs.length > 0) {
    initialObservations = getObservationsByGoal(goalToEdit, observations); // TODO: getObservationsByGoal
  }

  let inititialAddresses: (WrappedCondition | WrappedObservation)[] = [];
  inititialAddresses.push(...initialObservations);
  inititialAddresses.push(...initialConditions);

  if (!!defaultAddresses?.length) inititialAddresses = [...defaultAddresses];

  let description = goalToEdit?.getPlainDescription?.() ?? '';
  let dataFromPlanDefinition: GoalDataFromPlanDefinition | null = null;
  if (defaultPlanDefinition && !goalToEdit) {
    dataFromPlanDefinition = getDataFromPlanDefinition(
      goalStatuses,
      defaultPlanDefinition,
      startDate
    );
  }

  return {
    startDate: dataFromPlanDefinition?.goalStartDate ?? startDate,
    endDate: dataFromPlanDefinition?.goalEndDate ?? endDate,
    description: dataFromPlanDefinition?.description ?? description,
    selectedLifeCycleStatus: dataFromPlanDefinition?.lifecycleStatus ?? lifecycleStatus ?? null,
    selectedAchievementStatus:
      dataFromPlanDefinition?.achievementStatus ?? achievementStatus ?? null,
    selectedPathway: pathway ?? defaultPathway ?? null,
    comments: goalToEdit?.getComments?.() ?? '',
    selectedPlanDefinition: defaultPlanDefinition ? defaultPlanDefinition : null,
    selectedConditions: initialConditions,
    selectedObservations: initialObservations,
    selectedAddresses: inititialAddresses,
    isLoading: false,
    taskOwner: null,
  };
};

const initialErrorState: ErrorFieldState = {
  owner: null,
  lifecycleStatus: null,
  category: null,
  startDate: null,
  description: null,
  pathway: null,
  endDate: null,
};

const getInitialTaskOwnerState = () => ({
  isSearchMemberOpen: false,
  isTaskOwnerOpen: false,
  selectedTaskOwner: null,
  selectedRequesterPerformer: null,
  selectedPerformer: null,
  inputSelected: null,
  taskOwnerOptions: [...getMemberResourceTypes()],
});

const GoalModal = ({
  patient,
  onClose,
  open,
  pathways,
  defaultPathway,
  carePlan,
  onSuccessfulCreation,
  onSuccessfulEdit,
  goalToEdit,
  conditions,
  observations,
  planDefinitions,
  defaultAddresses,
  defaultPlanDefinition,
  ...goalModalProps
}: GoalModalProps): JSX.Element => {
  const {
    valueSets: [goalStatuses, goalAchievementStatuses],
  } = useValueSetsByIdentifiers(['ph-goal-lifecycle-status', 'ph-goal-achievement-status']);
  const { enqueueSnackbar } = useSnackbar();
  const { i18n } = useLocales();
  const { relatedGoals } = useGoalContext();

  const parentGoal = relatedGoals?.[0]?.wrappedGoal;
  const [
    {
      selectedPlanDefinition,
      selectedConditions,
      selectedObservations,
      selectedAddresses,
      selectedLifeCycleStatus,
      selectedAchievementStatus,
      selectedPathway,
      description,
      comments,
      startDate,
      endDate,
      isLoading,
      taskOwner,
    },
    updateState,
  ] = useObjectState<GoalModalState>(
    getInitialState({
      defaultPathway,
      goalToEdit,
      pathways,
      conditions,
      observations,
      goalStatuses: [],
    })
  );

  const [{ isSearchMemberOpen, selectedTaskOwner, taskOwnerOptions }, updateTaskOwner] =
    useObjectState<TaskSelectedMemberState>(getInitialTaskOwnerState());

  const [errors, updateErrorFieldState] = useObjectState<ErrorFieldState>(initialErrorState);

  const filteredPlanDefinitions = useMemo(() => {
    const planDefinitionsFiltered = selectedPathway?.code
      ? planDefinitions.filter((p) => p?.getGoalCategory?.() === selectedPathway?.code)
      : planDefinitions;

    if (
      defaultPlanDefinition &&
      !planDefinitionsFiltered.find(({ id }) => id === defaultPlanDefinition.id)
    )
      return [defaultPlanDefinition, ...planDefinitionsFiltered];
    return planDefinitionsFiltered;
  }, [planDefinitions, selectedPathway, defaultPlanDefinition]);

  useEffect(() => {
    if (!open) return;
    updateState(
      getInitialState({
        defaultPathway,
        goalToEdit,
        pathways,
        conditions,
        observations,
        goalStatuses: goalStatuses?.asList?.() ?? [],
        defaultAddresses,
        defaultPlanDefinition,
      })
    );
    updateErrorFieldState(initialErrorState);
    updateTaskOwner(getInitialTaskOwnerState());
  }, [
    goalStatuses,
    pathways,
    conditions,
    open,
    defaultPathway,
    goalToEdit,
    updateErrorFieldState,
    updateState,
    updateTaskOwner,
    defaultAddresses,
    defaultPlanDefinition,
    observations,
  ]);

  const addresses = useMemo(() => [...conditions, ...observations], [conditions, observations]);

  const handleOnSave = async () => {
    const errors: ErrorFieldState = {};
    updateErrorFieldState(initialErrorState);
    if (!selectedLifeCycleStatus) {
      errors.lifecycleStatus = 'Must select a valid status';
    }
    if (startDate && endDate && endDate?.isBefore(startDate, 'date')) {
      errors.startDate = "Start Date can't be less than End Date";
    }
    if (startDate && !startDate?.isValid()) {
      errors.startDate = 'Must specify a valid date';
    }
    if (endDate && !endDate?.isValid()) {
      errors.endDate = 'Must specify a valid date';
    }
    if (!description) {
      errors.description = 'Must specify a description';
    }
    if (Object.keys(errors).length) {
      updateErrorFieldState({ ...errors });
      return;
    }
    updateState({ isLoading: true });
    const isCreating = !goalToEdit;
    const { payload, actionCall, successfulMessage } = getGoalPayload(isCreating);
    const response = !goalToEdit
      ? await goalApiHelpers.createGoal(payload)
      : await goalApiHelpers?.editGoal(payload, goalToEdit?.id as string);
    updateState({ isLoading: false });
    if (!response) {
      enqueueSnackbar("There's been an error. Please Try Again", { variant: 'error' });
      return;
    }
    enqueueSnackbar(successfulMessage);
    actionCall?.(response);
    onClose?.({}, 'backdropClick');
  };

  const getGoalPayload = (isCreating: boolean): ActionData => {
    const addressesRef = selectedAddresses?.map((x) => x.resourceType + '/' + x?.id);

    return {
      payload: {
        description,

        comments,
        categoryCode: selectedPathway?.code,
        categoryDisplay: selectedPathway?.display,
        lifecycleStatus: selectedLifeCycleStatus?.code,
        achievementStatus: selectedAchievementStatus?.code ?? null,
        achievementStatusText: selectedAchievementStatus?.display ?? null,
        achievementStatusSystem: selectedAchievementStatus?.system ?? null,
        startDate: startDate?.isValid() ? startDate.format('YYYY-MM-DD') : null,
        endDate: endDate?.isValid() ? endDate.format('YYYY-MM-DD') : null,
        addressesRef,
        ...(isCreating
          ? {
              patientId: patient?.id,
              patientName: patient?.getFullName?.() ?? null,
              carePlanId: carePlan?.id,
              planDefinitionId: selectedPlanDefinition?.id,
              ownerId: taskOwner?.id ? `${taskOwner?.resourceType}/${taskOwner?.id}` : undefined,
              ownerDisplay: getOwnerDisplayLabel(taskOwner) ?? null,
              relatedGoals: relatedGoals?.length
                ? relatedGoals.map(({ extension }) => extension).flat()
                : undefined,
            }
          : {}),
      },
      actionCall: isCreating ? onSuccessfulCreation : onSuccessfulEdit,
      successfulMessage: isCreating ? 'Goal Succesfully Created' : 'Goal Successfully Modified',
    };
  };

  // const goalDescriptionCode =
  //   goalToEdit?.getDescriptionCode?.() ||
  //   selectedPlanDefinition?.getGoalDescriptionCoding?.()?.code;

  // const goalDescriptionDisplay = useMemo(
  //   () =>
  //     goalToEdit?.getDescriptionDisplay?.() ||
  //     selectedPlanDefinition?.getGoalDescriptionCoding?.()?.display,
  //   [goalToEdit, selectedPlanDefinition]
  // );

  const goalDescriptionCode =
    goalToEdit?.getDescriptionCode?.() ||
    selectedPlanDefinition?.getGoalDescriptionCoding?.()?.code;

  const goalDescriptionDisplay =
    goalToEdit?.getDescriptionDisplay?.() ||
    selectedPlanDefinition?.getGoalDescriptionCoding?.()?.display;

  return (
    <CustomModal
      open={open}
      title={
        !goalToEdit
          ? `${i18n('case.details.listofGoals.addNewGoal', 'crs')}`
          : `${i18n('case.details.listofGoals.editGoal', 'crs')}`
      }
      breadcrumbs={[
        'List of Goals',
        !goalToEdit
          ? `${i18n('case.details.listofGoals.addNewGoal', 'crs')}`
          : `${i18n('case.details.listofGoals.editGoal', 'crs')}`,
      ]}
      onSave={handleOnSave}
      onCancel={onClose as Function}
      isLoading={isLoading}
      containerSx={[{ overflow: 'scroll' }, isSearchMemberOpen ? { width: '94vw' } : {}]}
      childrenWithoutPadding={parentGoal ? <GoalInfoRibbon goal={parentGoal} /> : null}
      {...goalModalProps}
    >
      <Grid container my={3} justifyContent={'space-between'}>
        <Grid item xs={isSearchMemberOpen ? 6 : 12}>
          {!!patient && (
            <>
              <GridSection mt={0}>
                <GridItem>
                  <TextField
                    fullWidth
                    label={i18n('case.details.listofGoals.organization', 'crs')}
                    defaultValue={patient?.getOrganization()}
                    type="text"
                    placeholder={i18n('case.details.listofGoals.organization', 'crs')}
                    variant="outlined"
                    disabled
                  />
                </GridItem>
                <GridItem>
                  <TextField
                    fullWidth
                    label={i18n('case.details.listofGoals.patientName', 'crs')}
                    defaultValue={patient?.getFullName()}
                    type="text"
                    placeholder={i18n('case.details.listofGoals.patientName', 'crs')}
                    variant="outlined"
                    disabled
                  />
                </GridItem>
              </GridSection>
            </>
          )}
          {!!!goalToEdit && (
            <GridSection>
              <Autocomplete
                disabled={isLoading}
                value={selectedPathway}
                fullWidth
                onChange={(_: React.SyntheticEvent, newValue) => {
                  if (newValue) {
                    updateErrorFieldState({ pathway: null });
                  }
                  updateState({
                    selectedPathway: newValue,
                  });
                }}
                options={pathways}
                getOptionLabel={({ display }: ValueSetComposeIncludeConcept) => display as string}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    error={!!errors.pathway}
                    helperText={errors.pathway}
                    label={i18n('case.details.listofGoals.category', 'crs')}
                    variant="outlined"
                  />
                )}
              />
            </GridSection>
          )}
          {!!patient && (
            <GridSection>
              <GridItem xs={12}>
                <Autocomplete
                  disabled={isLoading}
                  multiple
                  value={selectedAddresses}
                  fullWidth
                  onChange={(_: React.SyntheticEvent, newValue) => {
                    updateState({
                      selectedAddresses: newValue,
                    });
                  }}
                  options={addresses}
                  getOptionLabel={(option) => {
                    if (option.resourceType === 'Observation' && option.valueCodeableConcept) {
                      return option.getDisplayValueFromValueCodeableConcept();
                    }

                    return option.code?.text ?? '';
                  }}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      label={i18n('case.details.listofGoals.addresses', 'crs')}
                      variant="outlined"
                    />
                  )}
                />
              </GridItem>
            </GridSection>
          )}
          {!!!goalToEdit && (
            <>
              <GridSection>
                <GridItem xs={selectedPlanDefinition ? gridItemColumnSize : 12}>
                  <Autocomplete
                    disabled={isLoading}
                    value={selectedPlanDefinition}
                    fullWidth
                    onChange={(_: React.SyntheticEvent, planDefinition) => {
                      let proposedPathway = pathways.find(
                        ({ code }) => code === planDefinition?.getGoalCategory?.()
                      );
                      const {
                        achievementStatus,
                        lifecycleStatus,
                        goalEndDate,
                        goalStartDate,
                        description,
                      } = getDataFromPlanDefinition(
                        goalStatuses?.asList?.() ?? [],
                        planDefinition,
                        startDate
                      );

                      updateState({
                        selectedPlanDefinition: planDefinition,
                        ...(lifecycleStatus ? { selectedLifeCycleStatus: lifecycleStatus } : {}),
                        ...(achievementStatus
                          ? {
                              selectedAchievementStatus: achievementStatus,
                            }
                          : {}),
                        ...(description ? { description } : {}),
                        ...(proposedPathway
                          ? { selectedPathway: proposedPathway as ValueSetComposeIncludeConcept }
                          : {}),
                        ...(goalStartDate && goalEndDate
                          ? { startDate: goalStartDate, endDate: goalEndDate }
                          : {}),
                      });
                    }}
                    options={filteredPlanDefinitions}
                    getOptionLabel={({ getGoalDescription }: WrappedPlanDefinition) =>
                      getGoalDescription?.() ?? ''
                    }
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        label={i18n('case.details.listofGoals.planDefinition', 'crs')}
                        variant="outlined"
                      />
                    )}
                  />
                </GridItem>
                {!!selectedPlanDefinition && (
                  <GridItem>
                    <Autocomplete
                      fullWidth
                      disabled={isLoading}
                      value={selectedTaskOwner}
                      onChange={(_: React.SyntheticEvent, taskOwnerOption) => {
                        const optionAsValue = taskOwnerOption as TaskSelectedMemberValue;
                        const optionAsOption = taskOwnerOption as TaskSelectedMemberOption;
                        if (
                          !optionAsValue?.resourceType &&
                          optionAsOption?.value !== 'Patient' &&
                          optionAsOption
                        ) {
                          updateTaskOwner({
                            isSearchMemberOpen: true,
                            selectedTaskOwner: optionAsOption,
                          });
                          return;
                        }
                        if (optionAsOption?.value === 'Patient') {
                          updateTaskOwner({ selectedTaskOwner: patient });
                          updateState({ taskOwner: patient });
                          return;
                        }
                        updateTaskOwner({ selectedTaskOwner: null });
                        updateState({ taskOwner: null });
                      }}
                      options={taskOwnerOptions}
                      getOptionLabel={(option) => {
                        const optionAsValue = option as TaskSelectedMemberValue;
                        const optionAsOption = option as TaskSelectedMemberOption;

                        if (!optionAsValue?.resourceType) return optionAsOption?.label as string;
                        let display = getOwnerDisplayLabel(optionAsValue);
                        if (optionAsValue?.resourceType === 'Patient') {
                          display = `${display} - Current Patient`;
                        }

                        return display;
                      }}
                      renderInput={(params) => (
                        <TextField {...params} label={'Task Owner'} variant="outlined" />
                      )}
                    />
                  </GridItem>
                )}
              </GridSection>
            </>
          )}
          <GridSection>
            <GridItem>
              <Autocomplete
                disabled={isLoading}
                value={selectedLifeCycleStatus}
                fullWidth
                onChange={(_: React.SyntheticEvent, newValue) => {
                  if (newValue)
                    updateErrorFieldState({
                      lifecycleStatus: null,
                    });
                  updateState({ selectedLifeCycleStatus: newValue });
                }}
                options={goalStatuses?.asList?.() ?? []}
                getOptionLabel={({ display }: GoalLifeCycleStatusOption) => display ?? ''}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    error={!!errors.lifecycleStatus}
                    helperText={errors.lifecycleStatus}
                    label={`${i18n('case.details.listofGoals.status', 'crs')}*`}
                    variant="outlined"
                  />
                )}
              />
            </GridItem>
            <GridItem>
              <Autocomplete
                disabled={isLoading}
                value={selectedAchievementStatus}
                fullWidth
                onChange={(_: React.SyntheticEvent, newValue) => {
                  updateState({
                    selectedAchievementStatus: newValue
                      ? {
                          code: newValue?.code,
                          display: newValue?.display,
                          system: goalAchievementStatuses?.getFirstIncludeConceptSystem?.(),
                        }
                      : null,
                  });
                }}
                options={goalAchievementStatuses?.asList?.() ?? []}
                getOptionLabel={({ display }: GoalAchievementStatusOption) => display ?? ''}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label={i18n('case.details.listofGoals.achievementStatus', 'crs')}
                    variant="outlined"
                  />
                )}
              />
            </GridItem>
          </GridSection>
          <GridSection>
            <GridItem>
              <DatePickerMoment
                disabled={isLoading}
                error={errors.startDate}
                label={i18n('case.details.listofGoals.startDate', 'crs')}
                value={startDate}
                onChange={(value) => {
                  if (value?.isValid?.() || value === null) {
                    updateErrorFieldState({
                      startDate: null,
                    });
                  }
                  updateState({
                    startDate: value,
                  });
                }}
              />
            </GridItem>
            <GridItem>
              <DatePickerMoment
                disabled={isLoading}
                error={errors.endDate}
                label={i18n('case.details.listofGoals.endDate', 'crs')}
                value={endDate}
                onChange={(value) => {
                  if (value?.isValid?.() || value === null) {
                    updateErrorFieldState({
                      endDate: null,
                    });
                  }
                  updateState({
                    endDate: value,
                  });
                }}
              />
            </GridItem>
          </GridSection>

          {!!goalDescriptionCode || !!goalDescriptionDisplay ? (
            <GridSection>
              <GridItem xs={12}>
                <TextField
                  fullWidth
                  disabled
                  value={goalDescriptionDisplay ?? ''}
                  label={i18n('case.details.listofGoals.descriptionCode', 'crs')}
                  variant="outlined"
                />
              </GridItem>
            </GridSection>
          ) : null}

          <GridSection>
            <GridItem xs={12}>
              <TextField
                fullWidth
                disabled={isLoading}
                multiline
                rows={4}
                value={description}
                onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                  const { value } = event.target;
                  if (value.trim().length) {
                    updateErrorFieldState({ description: null });
                  }
                  updateState({
                    description: value,
                  });
                }}
                label={`${i18n('case.details.listofGoals.description', 'crs')}*`}
                placeholder={`${i18n('case.details.listofGoals.description', 'crs')}*`}
                variant="outlined"
                error={!!errors.description}
                helperText={errors.description}
              />
            </GridItem>
          </GridSection>

          <GridSection>
            <TextField
              fullWidth
              disabled={isLoading}
              onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                updateState({
                  comments: event.target.value,
                });
              }}
              value={comments}
              label={i18n('case.details.listofGoals.comments', 'crs')}
              placeholder={i18n('case.details.listofGoals.comments', 'crs')}
              variant="outlined"
            />
          </GridSection>
        </Grid>
        {isSearchMemberOpen && (
          <Grid item xs={5.9}>
            <SearchMember
              patient={patient as WrappedPatient}
              onSelectResource={(resource) => {
                updateTaskOwner({ selectedTaskOwner: resource, isSearchMemberOpen: false });
                updateState({ taskOwner: resource });
              }}
              onClear={() => {
                updateTaskOwner({ selectedTaskOwner: null, isSearchMemberOpen: false });
                updateState({ taskOwner: null });
              }}
              externalResourceType={(() => {
                const selectedOption = selectedTaskOwner as TaskSelectedMemberOption;
                return selectedOption?.value as SearchMemberProps['externalResourceType'];
              })()}
            />
          </Grid>
        )}
      </Grid>
    </CustomModal>
  );
};

const getDataFromPlanDefinition = (
  goalStatuses: GoalLifeCycleStatusOption[],
  planDefinition: WrappedPlanDefinition | null,
  startDate: moment.Moment | null = null
): GoalDataFromPlanDefinition => {
  const lifecycleStatus = getValueSetConceptValue(
    goalStatuses ?? [],
    planDefinition?.getGoalLifecycleStatus?.()
  );

  const achievementStatus = planDefinition?.getGoalAchievementStatus?.();

  const goalDuration = planDefinition?.getGoalDuration?.();

  let goalStartDate: moment.Moment | null = null;
  let goalEndDate: moment.Moment | null = null;
  if (goalDuration?.unit && goalDuration?.value) {
    goalStartDate = startDate?.isValid?.() ? startDate : moment();
    goalEndDate = goalStartDate
      .clone()
      .add(goalDuration.value, goalDuration.unit as DurationInputArg2);
  }

  const description = planDefinition?.getGoalDescription?.() ?? '';

  return {
    lifecycleStatus,
    achievementStatus,
    goalStartDate,
    goalEndDate,
    description,
  };
};

export type GoalLifeCycleStatusOption = ValueSetComposeIncludeConcept;
export type GoalAchievementStatusOption = Coding;

export default GoalModal;
