import axios from 'axios';
import Cookies from 'js-cookie';
import jwtDecode from 'jwt-decode';
import moment from 'moment';
import { ReactNode, createContext, useContext, useEffect, useRef, useState } from 'react';
import {
  EXPIRE_SESSION_DIALOG_TIMEOUT_MS,
  TRIGGER_REFRESH_TOKEN_FLOW_BEFORE_EXP_SECONDS,
} from 'src/config';
import useActivityListeners from 'src/hooks/useActivityListeners';
import { getCareflowToken } from 'src/utils/auth-utils';
import { getBackEndBaseUrl } from 'src/utils/domain-utils';
import { expireSession } from 'src/utils/permissions/get.user.permissions';
import RefreshAuthTokenDialog from './RefreshAuthTokenDialog';
import { useStandardDialog } from './StandardDialogProvider';

interface IRefreshAuthWatcherProviderContextData {}

const initialValue: IRefreshAuthWatcherProviderContextData = {};

const RefreshAuthWatcherProviderContext =
  createContext<IRefreshAuthWatcherProviderContextData>(initialValue);

export function useRefreshAuthWatcherBackdrop() {
  const {} = useContext<IRefreshAuthWatcherProviderContextData>(RefreshAuthWatcherProviderContext);
  return {};
}

interface ICounterData {
  start: boolean;
  tokenExp: number;
  refreshBefore: number;
  refreshTokenExp: number;
  retry: number;
}

interface IRefreshAuthWatcherProviderProps {
  children: ReactNode;
  meta?: ReactNode;
  //   handleOnClose: () => void;
}

interface IRefreshTokenResponse {
  access_token: string;
  refresh_token: string;
  careflow_token: string;
}

async function refreshToken(): Promise<IRefreshTokenResponse | undefined> {
  const baseApiUrl = getBackEndBaseUrl();
  const refreshToken = Cookies.get('refreshToken');
  const careflowToken = getCareflowToken();

  if (!refreshToken || !careflowToken) {
    return;
  }

  let headers: any = {};
  let res;

  try {
    res = await axios.post<IRefreshTokenResponse>(
      baseApiUrl + '/auth/refresh-token',
      {
        careflow_token: careflowToken,
        refresh_token: refreshToken,
      },
      {
        headers: headers,
      }
    );
  } catch (e) {
    console.log(e);
  }

  if (res?.data?.access_token) {
    return res.data as IRefreshTokenResponse;
  }

  return undefined;
}

export default function RefreshAuthWatcherProvider({
  children,
  meta,
}: IRefreshAuthWatcherProviderProps) {
  const initalCounterData: ICounterData = {
    start: false,
    tokenExp: 0,
    refreshBefore:
      TRIGGER_REFRESH_TOKEN_FLOW_BEFORE_EXP_SECONDS > 0
        ? TRIGGER_REFRESH_TOKEN_FLOW_BEFORE_EXP_SECONDS * 1000
        : 20 * 1000, // 20 seconds
    refreshTokenExp: 0,
    retry: 0,
  };
  const [counterData, setCounterData] = useState<ICounterData>(initalCounterData);
  const firstStart = useRef(true);
  const tickExpireSession = useRef<any>();
  const tickRefreshSession = useRef<any>();
  const standardDialog = useStandardDialog();
  const activityListeners = useActivityListeners({
    afterMaxInactivityHandler: showRefreshTokenDialog,
  });

  useEffect(() => {
    if (firstStart.current) {
      firstStart.current = !firstStart.current;
      return;
    }

    const expDateMili = counterData.tokenExp * 1000;
    const expDate = moment(expDateMili);

    console.debug(
      'RefreshAuthFlow access token will expire at: ' + expDateMili + ' date:' + expDate.toLocaleString()
    );
    return clearTimeouts;
  }, [counterData.tokenExp]);

  function clearTimeouts() {
    clearTimeout(tickRefreshSession.current);
    clearTimeout(tickExpireSession.current);
  }

  function getTokenTimeLeftMili(tokenExpInSeconds: number) {
    const nowSeconds = moment.now() / 1000;

    if (tokenExpInSeconds < nowSeconds) {
      return 0;
    }

    const leftTimeSeconds = tokenExpInSeconds - nowSeconds;
    return Math.floor(leftTimeSeconds * 1000);
  }

  function processRefreshFlow() {
    const accessTokenTimeLeft = getTokenTimeLeftMili(counterData.tokenExp);
    const refreshTokenTimeLeft = getTokenTimeLeftMili(counterData.refreshTokenExp);
    const timeLeft =
      accessTokenTimeLeft <= refreshTokenTimeLeft ? accessTokenTimeLeft : refreshTokenTimeLeft;

    if (counterData.start && timeLeft > 0) {
      const extraMessage =
        counterData.retry > 3
          ? 'Session expired after retrying to auto refresh token and fail to refresh it'
          : 'access token expired (' + counterData.retry + ')';

      setExpireTimeout(timeLeft, extraMessage);
    }

    if (counterData.start && timeLeft > 0 && counterData.retry < 3) {
      const refreshTimeMili =
        timeLeft > counterData.refreshBefore ? timeLeft - counterData.refreshBefore : timeLeft;

      setRefreshTokenFlowTimeout(refreshTimeMili);
    }
  }

  function handleContinueSession() {
    standardDialog.close();
    activityListeners.enable();
  }

  function showRefreshTokenDialog() {
    if (
      window.location.pathname === '/select-tenant' ||
      window.location.pathname === '/session-expired'
    ) {
      return;
    }

    console.debug('RefreshAuthFlow RefreshAuthTokenDialog is open');

    standardDialog.open(
      <RefreshAuthTokenDialog
        timeLeft={EXPIRE_SESSION_DIALOG_TIMEOUT_MS}
        handleContinueSession={handleContinueSession}
        handleExpireSession={() => handleExpire('No user interaction in session refresh dialog')}
        handleExpireSessionByUser={() => handleExpire('User end session in session refresh dialog')}
      />,
      'xs'
    );
  }

  function setRefreshTokenFlowTimeout(timeLeftMili: number) {
    const timeLeftSeconds = Math.floor(timeLeftMili / 1000);
    console.debug(`RefreshAuthFlow autorefresh will be triggered in: ${timeLeftSeconds} s`);

    if (tickRefreshSession.current !== undefined) {
      clearTimeout(tickRefreshSession.current);
    }

    tickRefreshSession.current = setTimeout(refreshFn, timeLeftMili);
  }

  async function handleExpire(extraMessage?: string) {
    setCounterData((prev) => initalCounterData);
    standardDialog.close();
    clearTimeouts();
    await expireSession(extraMessage);
  }

  function setExpireTimeout(timeLeftMili: number, extraMessage?: string) {
    const timeLeftSeconds = Math.floor(timeLeftMili / 1000);
    console.debug(`RefreshAuthFlow expire session will be triggered in: ${timeLeftSeconds} s`);

    if (tickExpireSession.current !== undefined) {
      clearTimeout(tickExpireSession.current);
    }

    tickExpireSession.current = setTimeout(() => handleExpire(extraMessage), timeLeftMili);
  }

  async function refreshFn() {
    const res = await refreshToken();

    if (res === undefined) {
      setCounterData((prev) => ({
        ...prev,
        retry: prev.retry + 1,
      }));
      throw new Error('No refresh token response valid');
    }

    const decodedAccessToken = jwtDecode<{ exp: number }>(res.access_token);
    const decodedRefreshToken = jwtDecode<{ exp: number }>(res.refresh_token);
    const decodedCareflowToken = jwtDecode<{ exp: number }>(res.careflow_token);
    const actualHostName = window.location.hostname; //dev-careflo2.mycare.site

    console.debug('DEBUG actualHostName:', actualHostName);

    Cookies.remove('accessCode', { path: '/' });
    const aCExpDate = new Date(decodedAccessToken.exp * 1000);
    Cookies.set('accessCode', res.access_token, {
      expires: aCExpDate,
      path: '/',
    });

    Cookies.remove('refreshToken', { path: '/' });
    const rTExpDate = new Date(decodedRefreshToken.exp * 1000);
    Cookies.set('refreshToken', res.refresh_token, {
      expires: rTExpDate,
      path: '/',
    });

    Cookies.remove('careflowToken', { path: '/' });
    const cTExpDate = new Date(decodedCareflowToken.exp * 1000);
    Cookies.set('careflowToken', res.careflow_token, {
      expires: cTExpDate,
      path: '/',
    });

    setCounterData((prev) => ({
      start: true,
      tokenExp: decodedAccessToken.exp,
      refreshBefore: prev.refreshBefore,
      refreshTokenExp: decodedRefreshToken.exp,
      retry: 0,
    }));
  }

  function proccessAuthToken() {
    // TODO: get token exp
    const accessToken = Cookies.get('accessCode');
    const refreshToken = Cookies.get('refreshToken');

    if (!accessToken || !refreshToken) {
      return;
    }

    const decodedAccessToken = jwtDecode<{ exp: number }>(accessToken);
    const decodedRefreshToken = jwtDecode<{ exp: number }>(refreshToken);

    setCounterData((prev) => ({
      start: true,
      tokenExp: decodedAccessToken.exp,
      refreshBefore: prev.refreshBefore,
      refreshTokenExp: decodedRefreshToken.exp,
      retry: prev.retry,
    }));
  }

  function getTokenTimeLeftSeconds(tokenExpInSeconds: number) {
    const nowSeconds = moment.now() / 1000;

    if (tokenExpInSeconds < nowSeconds) {
      return 0;
    }

    const leftTimeSeconds = tokenExpInSeconds - nowSeconds;
    return Math.floor(leftTimeSeconds);
  }

  function logExpireInfo() {
    const accessTokenExpDateMili = counterData.tokenExp * 1000;
    const accessTokenExpDate = moment(accessTokenExpDateMili);
    const accessTokenTimeLeft = getTokenTimeLeftSeconds(counterData.tokenExp);

    console.debug(
      `RefreshAuthFlow access token time left: ${accessTokenTimeLeft} s will expire at: ${accessTokenExpDateMili} date: ${accessTokenExpDate.toLocaleString()}`
    );

    const refreshTokenExpDateMili = counterData.refreshTokenExp * 1000;
    const refreshTokenExpDate = moment(refreshTokenExpDateMili);
    const refreshTokenTimeLeft = getTokenTimeLeftSeconds(counterData.refreshTokenExp);

    console.debug(
      `RefreshAuthFlow refresh token time left: ${refreshTokenTimeLeft} s will expire at: ${refreshTokenExpDateMili} date: ${refreshTokenExpDate.toLocaleString()}`
    );
  }

  useEffect(() => {
    proccessAuthToken();
    activityListeners.enable();
  }, []);

  useEffect(() => {
    if (firstStart.current) {
      firstStart.current = false;
      return;
    }

    processRefreshFlow();

    return clearTimeouts;
  }, [counterData]);

  useEffect(() => {
    if (firstStart.current || (counterData.tokenExp === 0 && counterData.refreshTokenExp === 0)) {
      return;
    }

    logExpireInfo();
  }, [counterData.tokenExp, counterData.refreshTokenExp]);

  return (
    <RefreshAuthWatcherProviderContext.Provider value={{}}>
      <div>{children}</div>
    </RefreshAuthWatcherProviderContext.Provider>
  );
}
