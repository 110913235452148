import { useEffect, useState } from 'react';
import { SettingsValueProps } from 'src/components/settings/type';
import { defaultSettings } from 'src/config';
import { Coding } from 'src/nicheaim-infrastructure/application/adapters/out/repositories/fhir/resources';
import { getTenantCodeFromCareflowToken } from 'src/utils/auth-utils';
import useRegistry from './useRegistry';

export enum BannerInfoName {
  MRN = 'patientMRN',
  AGE = 'patientAge',
  GENDER = 'patientGender',
  PRIMARY_PHONE = 'patientPrimaryPhone',
  PRIMARY_EMAIL = 'patientPrimaryEmail',
  MEDICAID = 'patientMedicaId',
  PRIMARY_ZIPCODE = 'patientPrimaryZipCode',
  MANAGING_ORG = 'patientManagingOrg',
  SIL_SCORE = 'patientSILScore',
}

export interface BannerData {
  order: number;
  name: BannerInfoName;
}

export interface TenantThemeConfig {
  logo: string;
  tenantName: string;
  pagePrefix: string;
  tenantFavicon: string;
  tenantSpinnerLogo: string;
  url?: string;
  settings: SettingsValueProps;
}

export interface CareTeamTenantConfigData {
  defaultValues?: {
    category?: Coding;
    status?: string;
  };
  readOnly?: string[];
  careTeamSearchMember?: {
    selectTypes?: [{
      label: string, 
      value: string
    }],
    selectAllowed?: {
      selectCareTeam?: boolean;
      selectMember?: boolean;
    }
  };
}

export interface MyDashboardPatientsDashboardData {
  filters?: {
    generalPractitioner: boolean;
    careTeamMember: boolean;
  };
}

export interface MyDashboardConfigData {
  patientsDashboard?: MyDashboardPatientsDashboardData;
}

export interface WorkflowDisposition {
  showProviderWindow?: boolean;
}

interface ExternalLinkData {
  buttonTitle?: string;
  url?: string;
}

export interface TenantConfigData {
  finishedLoading?: boolean;
  languageResource?: string | null;
  theme?: TenantThemeConfig;
  componentsData: {
    workflow: any;
    showSILInformation?: boolean;
    showMedicaID?: boolean;
    showOptInStatus?: boolean;
    showGender?: boolean;
    showSubGoals?: {
      includeSubGoals: boolean;
      noOfSubGrids: number;
    };
    episodeOfCare?: {
      defaultValues?: {
        type?: Coding;
      };
    };
    careTeam?: CareTeamTenantConfigData;
    practitioner?: {
      providerDirectoryURL?: string;
    };
    workflowDisposition?: WorkflowDisposition;
    myDashboard?: MyDashboardConfigData;
    observation?: {
      defaultValues?: {
        category?: {
          code?: string;
        };
      };
    };
    consent?: {
      defaultValues?: {
        status?: string;
        scope?: string;
      };
    };
    patientLandingPageRoute?: string;
    userSupport?: {
      urlForm?: string;
    };
    externalLink?: {
      [key: string]: ExternalLinkData;
    },
    user?: {
      requiredPhone?: boolean;
    }
  };
}

export interface TenantConfigDataResponse {
  configData: TenantConfigData;
}

export const initialTenantConfigData: TenantConfigData = {
  finishedLoading: false,
  languageResource: null,
  theme: {
    tenantName: 'MyCareCircle',
    logo: '/logo/my_care_circle_horizontal_logo.png',
    pagePrefix: 'MyCareCircle - ',
    tenantFavicon: '/favicon/MyCareCircle-Icon.png',
    tenantSpinnerLogo: '/logo/my_care_circle_horizontal_logo.png',
    settings: defaultSettings,
  },
  componentsData: {
    workflow: {},
    showSILInformation: false,
    showMedicaID: false,
    showOptInStatus: false,
    showGender: true
  },
};

const useTenantConfigData = (): TenantConfigData => {
  const [tenantConfigData, setTenantConfigData] =
    useState<TenantConfigData>(initialTenantConfigData);
  const { data: registry } = useRegistry({ filter: { keyRegistry: 'tenant-config-data' } });

  useEffect(() => {
    const userTenantCode = getTenantCodeFromCareflowToken();
    if (!registry?.[0] || !userTenantCode) return;
    try {
      const tenantConfig = JSON.parse(registry[0].keyValue) as TenantConfigDataResponse[];
      if (tenantConfig)
        setTenantConfigData({ ...initialTenantConfigData, ...tenantConfig, finishedLoading: true });
    } catch (error) {}
  }, [registry]);

  return tenantConfigData;
};

export default useTenantConfigData;
